<template>
  <div class="common-layout">
    <div style="width: 100%" v-if="key != '/home'">
      <div class="header-container clearfix">
        <div style="display: inline-block">
          <el-dropdown>
            <div
              style="
                height: 145px;
                display: flex;
                align-items: center;
                margin: 0 30px;
                color: #fff;
              "
            >
              <img
                src="../assets/image/user.jpeg"
                style="width: 46px; height: 46px; margin-right: 10px; border-radius: 50%"
                alt=""
              />{{ username }}
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <span @click="changePassword" style="margin-left: 10px">修改密码</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="goLogin" style="margin-left: 10px">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <ul class="nav-ul">
          <li
            :class="key == item.path ? 'active' : ''"
            @click="goNavPage(item)"
            v-for="item in navList"
            :key="item.id"
          >
            <div>
              <span></span>
            </div>
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div
      style="background: #f5f5f5"
      :style="key == '/home' ? { 'min-height': 'calc(100vh - 145px)' } : {}"
    >
      <router-view />
    </div>

    <el-dialog title="修改密码" v-model="passDialog" width="500px">
      <el-form :model="passwordForm" label-width="90px">
        <el-form-item label="旧密码">
          <el-input v-model="passwordForm[0]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="passwordForm[1]" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="passwordForm[2]" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="passDialog = false" type="">取消</el-button>
        <el-button type="primary" @click="submitPass">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { logout, changePassword } from "../utils/api";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      activeId: 0,
      passDialog: false,
      passwordForm: ["", "", ""],
      navList1: [
        {
          path: "/home",
          name: "首页",
          id: 0,
        },
        {
          path: "/timedTask",
          name: "定时任务",
          id: 1,
        },
        {
          path: "/temporaryDuty",
          name: "临时任务",
          id: 2,
        },
        {
          path: "/broadcastLive",
          name: "实时广播",
          id: 8,
        },
        {
          path: "/eventLinkage",
          name: "事件联动",
          id: 7,
        },
        {
          path: "/libraryManagement",
          name: "曲库管理",
          id: 3,
        },
        {
          path: "/userManagement",
          name: "用户管理",
          id: 4,
        },
        {
          path: "/mapManagement",
          name: "地图管理",
          id: 5,
        },
        {
          path: "/baseManagement",
          name: "基本设置",
          id: 6,
        },
        {
          path: "/logQuery",
          name: "日志查询",
          id: 9,
        },
      ],
      navList2: [
        {
          path: "/home",
          name: "首页",
          id: 0,
        },
        {
          path: "/timedTask",
          name: "定时任务",
          id: 1,
        },
        {
          path: "/temporaryDuty",
          name: "临时任务",
          id: 2,
        },
        {
          path: "/broadcastLive",
          name: "实时广播",
          id: 8,
        },
        {
          path: "/eventLinkage",
          name: "事件联动",
          id: 7,
        },
        {
          path: "/libraryManagement",
          name: "曲库管理",
          id: 3,
        },
        {
          path: "/mapManagement",
          name: "地图管理",
          id: 5,
        },
        // {
        //   path: "/baseManagement",
        //   name: "基本设置",
        //   id: 6,
        // },
        {
          path: "/logQuery",
          name: "日志查询",
          id: 9,
        },
      ],
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    username() {
      return window.localStorage.getItem("username");
    },
    navList() {
      let roleNum = [];
      if (window.sessionStorage.getItem("userRole")) {
        roleNum = JSON.parse(window.sessionStorage.getItem("userRole"));
      }
      if (roleNum.indexOf(3) > -1) {
        //管理员
        return this.navList1;
      } else {
        return this.navList2;
      }
    },
  },
  methods: {
    submitPass() {
      let that = this;
      if (
        this.passwordForm[0] != "" &&
        this.passwordForm[1] != "" &&
        this.passwordForm[2] != "" &&
        this.passwordForm[1] == this.passwordForm[2]
      ) {
        changePassword({
          params: [this.passwordForm[0], this.passwordForm[1]],
        }).then((res) => {
          if (!res.error) {
            ElMessage({
              type: "success",
              message: "修改成功！",
              customClass: "mzindex",
            });
            this.passDialog = false;
            setTimeout(function () {
              that.$router.go(0);
            }, 800);
          }
        });
      } else {
        ElMessage({
          type: "error",
          message: "输入不能为空，且新密码和确认密码必须一致！",
          customClass: "mzindex",
        });
      }
    },
    goNavPage(item) {
      this.activeId = item.id;
      this.$router.push(item.path);
    },
    goLogin() {
      logout().then((res) => {
        if (!res.error) {
          this.$router.push("/login");
        }
      });
    },
    changePassword() {
      this.passDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-layout {
  min-width: 1600px;
}
.header-container {
  width: 100%;
  height: 145px;
  background: #216cc9;
  display: block;
  & .nav-ul {
    float: right;
    display: flex;
    height: 145px;
    align-items: center;
    & li {
      width: 145px;
      height: 77px;
      padding-top: 8px;
      border-radius: 5px;
      text-align: center;
      float: left;
      line-height: 26px;
      cursor: pointer;
      color: #fff;
      font-size: 24px;
      & > div {
        width: 100%;
        text-align: center;

        & > span {
          width: 35px;
          height: 35px;
          display: inline-block;
          background-image: url(../assets/image/navIcon.png);
        }
      }
    }
    & li:nth-child(1) > div > span {
      background-position: 0px 0;
    }
    & li:nth-child(2) > div > span {
      background-position: -42px 2px;
    }
    & li:nth-child(3) > div > span {
      background-position: -82px 2px;
    }
    & li:nth-child(4) > div > span {
      background-position: -122px 2px;
    }
    & li:nth-child(5) > div > span {
      background-position: -162px 2px;
    }
    & li:nth-child(6) > div > span {
      background-position: -202px 2px;
    }
    & li:nth-child(7) > div > span {
      background-position: -270px 2px;
    }
    & li:nth-child(8) > div > span {
      background-position: -310px 2px;
    }
    & li:nth-child(9) > div > span {
      background-position: -354px 0px;
    }
    & li:nth-child(10) > div > span {
      background-position: -400px 0px;
    }
  }
  & > div {
    float: right;
    color: #333;
    line-height: 60px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.active {
  background: #278afe;
  color: #fff !important;
}
</style>
