<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>
<style>
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}
.mzindex {
  z-index: 39999 !important;
}
.line2{
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
  word-break: break-all;
}
.line1{
  overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
}
</style>
<style lang="scss">
html >>> .BMap_bubble_pop {
  background: url(./assets/image/mapTips.png);
}
</style>