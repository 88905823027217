<template>
  <div class="home">
    <img class="indexTop" src="../assets/image/indexTop.png" alt="" srcset="" />
    <div class="indexTop-text">{{ title ? title : "悠普仕广播云平台" }}</div>
    <div id="mapDiv" style="width: 100%; height: 100%"></div>

    <!-- 左边表格 -->
    <div class="index-left-table">
      <div class="index-left-table-one">
        <div
          id="main"
          ref="mapBox"
          style="width: 400px; height: 300px; padding-top: 60px"
        ></div>
      </div>
      <div class="index-left-table-tow">
        <div style="width: 100%; height: 10px"></div>
        <ul>
          <li>
            <span>序号</span>
            <span>状态</span>
            <span>开始时间</span>
            <span>内容</span>
          </li>
          <!-- <li v-for="(item,index) in devicelist"> -->
          <li v-for="(item, index) in tableData">
            <span>{{ index + 1 }}</span>
            <span>{{ item[1] }}</span>
            <span>{{ item[2] }}</span>
            <span>{{ item[3] }}</span>
          </li>
        </ul>
        <!-- <el-pagination :total="total" layout="prev, pager, next" :current-page="pageNum + 1" :page-count="pageSize" @current-change="currentChange"></el-pagination> -->
      </div>
    </div>

    <!-- 右边表格 -->
    <div class="index-right-table">
      <div class="index-right-table-one">
        <div style="width: 380px; height: 300px; margin: 80px 0 0 160px" id="main2"></div>
      </div>
      <div class="index-right-table-tow"></div>
    </div>

    <!-- 底部导航 -->
    <div class="indexBottom">
      <ul :class="navList.length == 8 ? 'indexBottomNav' : 'indexBottomNav2'">
        <li v-for="item in navList" @click="$router.push(item)"></li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import sortTable from "@/components/sortTable.vue";
import {
  deviceList,
  getDeviceTotal,
  alldeviceVolume,
  getPlatformTitle,
  getMapfileMode,
  visualNoise,
  getTasksSummary,
} from "@/utils/api.js";
import { ElMessage } from "element-plus";
// import { toRefs } from "vue";
import * as echarts from "echarts";
export default {
  name: "Home",
  components: {
    sortTable,
  },
  data() {
    return {
      indexNav: [
        "/timedTask",
        "/temporaryDuty",
        "/broadcastLive",
        "/eventLinkage",
        "/libraryManagement",
        "/userManagement",
        "/mapManagement",
        "/baseManagement",
      ],
      indexNav2: [
        "/timedTask",
        "/temporaryDuty",
        "/broadcastLive",
        "/eventLinkage",
        "/libraryManagement",
        "/mapManagement",
      ],
      total: 0,
      pageNum: 0,
      devicelist: [],
      allDevice: [],
      volumeNum: 0,
      map: null,
      tableData: [],
      setTime: null,
      pageSise: 0,
      bardata: [],
      map: null,
      title: "",
    };
  },
  created() {
    let that = this;
    this.getDeviceList();
    this.deviceTotal();
    this.setTime = setInterval(function () {
      that.getDeviceList();
      that.deviceTotal();
    }, 8000);
  },
  computed: {
    navList() {
      let roleNum = [];
      if (window.sessionStorage.getItem("userRole")) {
        roleNum = JSON.parse(window.sessionStorage.getItem("userRole"));
      }
      if (roleNum.indexOf(3) > -1) {
        //管理员
        return this.indexNav;
      } else {
        return this.indexNav2;
      }
    },
  },
  watch: {},
  methods: {
    currentChange(val) {
      this.pageNum = val - 1;
      this.getDeviceList();
    },
    getTasksSummaryapi() {
      getTasksSummary({ params: [-1] }).then((res) => {
        if (!res.error) {
          this.tableData = res.result;
        }
      });
    },
    deviceTotal() {
      getDeviceTotal().then((res) => {
        if (res.result && res.result.length > 0) {
          this.allDevice = res.result;
          this.volumeNum = res.result[3];
          this.bardata[0] = {
            name: "设备总数",
            value: res.result[0],
          };
          this.bardata[1] = {
            name: "在线设备",
            value: res.result[1],
          };
          this.bardata[2] = {
            name: "离线设备",
            value: res.result[2],
          };
          this.setBarEcharts();
        }
      });
    },

    setAlldeviceVolume() {
      alldeviceVolume({
        params: [this.volumeNum],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            message: "设置成功！",
            type: "success",
            customClass: "mzindex",
          });
          //更新了音量刷新数据
          this.devicelist = [];
          this.getDeviceList();
          this.deviceTotal();
        }
      });
    },

    getDeviceList() {
      deviceList({ params: [-1] }).then((res) => {
        if (!res.error) {
          this.devicelist = res.result[0];
          this.total = res.result[1];
          this.pageSize = res.result[2];
          let newVdataArr = this.devicelist.map((m) => {
            return {
              lng: m[4],
              lat: m[5],
              status: m[0],
              name: m[2] ? m[2] : m[1],
              volume: m[3],
            };
          });
          this.markerPoint(newVdataArr);
        }
      });
    },

    markerPoint(site) {
      console.log(site);
      let that = this;
      var siteForB = [];
      var total = 0; //总记录数
      var groupCount = 0; //每次转十条
      if (site.length % 10 > 0) {
        groupCount = site.length / 10 + 1;
      } else {
        groupCount = site.length / 10;
      }
      new Promise((resolve, reject) => {
        for (var i = 0; i < groupCount; i++) {
          //外层循环，有多少组十条
          var pos = new Array();
          for (var j = 0; j < 10; j++) {
            //内层循环，每组十条
            if (total < site.length) {
              //不超过总记录数结束
              var point = new BMapGL.Point(site[i * 10 + j].lng, site[i * 10 + j].lat);
              pos.push(point);
            }
            total++;
          }
          var convertor = new BMapGL.Convertor();
          convertor.translate(pos, 1, 5, function (data) {
            if (data.status === 0) {
              siteForB = siteForB.concat(JSON.parse(JSON.stringify(data.points)));
            }
          });
        }
        resolve(siteForB);
      }).then((res) => {
        setTimeout(function () {
          site.forEach((item, i) => {
            //设置自定义图标时，需要使用require
            let myIcon = null;
            if (item.status) {
              myIcon = new BMapGL.Icon(
                require("@/assets/image/online.png"),
                new BMapGL.Size(30, 45),
                {
                  offset: new BMapGL.Size(0, 0), // 指定定位位置
                }
              );
            } else {
              myIcon = new BMapGL.Icon(
                require("@/assets/image/offline.png"),
                new BMapGL.Size(30, 45),
                {
                  offset: new BMapGL.Size(0, 0), // 指定定位位置
                }
              );
            }
            setTimeout(function () {
              let markers = new BMapGL.Marker(
                new BMapGL.Point(item.lng, item.lat),
                { icon: myIcon }
              );
              that.map.addOverlay(markers);

              let opts = {
                width: 170, // 信息窗口宽度
                height: 155, // 信息窗口高度
                title: "测试", // 信息窗口标题
                message: "",
              };
              let Content = `
                <div class="line1" style="width:120px;color: #fff;line-height: 40px;margin: 3px 10px 0 15px;font-size:16px">${
                  item.name
                }</div>
                <p style="color:#9ec5f4;line-height:24px;margin-top:10px;padding:0 20px">状态：<span style="color:${
                  item.status ? "#1ae264" : "#9ec5f4"
                }"></span>${item.status ? "在线" : "离线"}</p>
                <p style="color:#9ec5f4;line-height:24px;padding:0 20px">音量：<span style="color:#35d4f4">${
                  item.volume
                }</span></p>
                <p class="line1" style="width:140px;color:#9ec5f4;line-height:24px;padding:0 20px">编号：<span style="color:#35d4f4">${
                  item.name
                }</span></p>
              `;
              let infoWindow = new BMapGL.InfoWindow(Content, opts);
              markers.addEventListener("click", function () {
                this.openInfoWindow(infoWindow);
              });
            }, 1);
          });
        }, 300);
      });
    },

    setBarEcharts() {
      let that = this;
      this.$nextTick(function () {
        var chartDom = this.$refs.mapBox;
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["35%", "60%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 5,
                borderColor: "transparent",
                borderWidth: 2,
              },
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "16",
                  color: "#fff",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: that.bardata,
            },
          ],
        };

        option && myChart.setOption(option);
      });
    },

    lineChart(data, data2) {
      this.$nextTick(function () {
        var chartDom = document.getElementById("main2");
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          xAxis: {
            type: "category",
            data: data,
            axisLabel: {
              textStyle: {
                color: "#fff",
                fontSize: 12,
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#fff",
                fontSize: 12,
              },
            },
          },
          series: [
            {
              data: data2,
              type: "line",
              smooth: true,
            },
          ],
        };
        chartDom.setAttribute("_echarts_instance_", "");
        option && myChart.setOption(option);
      });
    },
  },
  mounted() {
    // GL版命名空间为BMapGL
    // 按住鼠标右键，修改倾斜角和角度
    getMapfileMode({
      params: [],
    }).then((res) => {
      this.map = new BMapGL.Map("mapDiv"); // 创建Map实例
      this.map.centerAndZoom(
        new BMapGL.Point(
          res.result[1] ? res.result[1] : "113.53",
          res.result[2] ? res.result[2] : "22.65"
        ),
        res.result[3] ? res.result[3] : 20
      ); // 初始化地图,设置中心点坐标和地图级别
      this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      this.map.setHeading(64.5);
      this.map.setTilt(73);
      this.map.setMapStyleV2({ styleId: "68c77afccb6098f5574d0e6b49bb7f66" });
    });

    this.getTasksSummaryapi();

    visualNoise({ params: [] }).then((res) => {
      if (Object.keys(res.result[0]).length > 0) {
        this.lineChart(Object.keys(res.result[0]), Object.values(res.result[0]));
      }
    });

    getPlatformTitle({ params: [] }).then((res) => {
      this.title = res.result[0];
    });
  },
  beforeUnmount() {
    clearInterval(this.setTime);
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 1080px;
  background: #08101f;
  overflow: hidden;
  position: relative;
  & > .indexTop {
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    height: 152px;
  }
  & > .indexTop-text {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10001;
    transform: translateX(-50%);
    font-size: 40px;
    color: #fff;
    line-height: 88px;
    font-family: "fangzheng";
    letter-spacing: 5px;
    background-image: -webkit-linear-gradient(top, #f4faff, #b5e8fe, #82d9fe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.sort-table {
  width: 100%;
  height: calc(50vh - 110px);
  display: inline-block;
  margin-top: 50vh;
}

.home-top-message {
  width: 100%;
  padding: 16px;
  line-height: 32px;
  font-size: 14px;

  & span {
    margin: 0 5px;
  }
}
.index-left-table {
  position: absolute;
  left: 0;
  top: 47px;
  width: 533px;
  height: 822px;
  z-index: 1000;
  & > .index-left-table-one {
    width: 100%;
    height: 366px;
    background: url(../assets/image/shebeiyulan.png) no-repeat;
    background-size: 100% 100%;
  }
  & > .index-left-table-tow {
    width: 100%;
    height: 456px;
    background: url(../assets/image/renwuliebiao.png) no-repeat;
    background-size: 100% 100%;

    & > ul {
      width: 340px;
      height: 220px;
      overflow-y: auto;
      padding: 20px 20px 20px 0;
      margin-left: 20px;
      margin-top: 90px;
      background: url(../assets/image/homeList.png) no-repeat;
      background-size: cover;
      & > li:nth-child(1) span {
        color: #34dffb;
      }
      & > li {
        height: 30px;
        line-height: 30px;
        display: flex;
        & > span:nth-child(1),
        & > span:nth-child(2) {
          width: 62px;
          text-align: center;
          color: #fff;
        }
        & > span:nth-child(3),
        & > span:nth-child(4) {
          flex: 1;
          text-align: center;
          color: #fff;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.index-right-table {
  position: absolute;
  right: 0;
  top: 47px;
  width: 533px;
  height: 842px;
  z-index: 1000;
  background: url("../assets/image/zaoyin.png") no-repeat;
}
.indexBottom {
  width: 100%;
  height: 300px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(../assets/image/indexBottom.png) no-repeat;
  background-position: center bottom;
  background-size: auto 233px;
  & > .indexBottomNav {
    width: 1166px;
    height: 280px;
    margin: 0 auto;
    position: relative;
    & > li {
      width: 78px;
      height: 100px;
      position: absolute;
      z-index: 10000;
    }
    & > li:nth-child(1) {
      background-position: 0 0;
      background: url(../assets/image/indexNav.png) no-repeat;
      top: 40px;
      left: 0;
    }
    & > li:nth-child(2) {
      background-position: -90px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 33px;
      left: 160px;
    }
    & > li:nth-child(3) {
      background-position: -280px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 25px;
      left: 320px;
    }
    & > li:nth-child(4) {
      background-position: -480px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 20px;
      left: 480px;
    }
    & > li:nth-child(5) {
      background-position: -180px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 22px;
      left: 640px;
    }
    & > li:nth-child(6) {
      background-position: -380px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 25px;
      left: 800px;
    }
    & > li:nth-child(7) {
      background-position: -572px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 30px;
      left: 960px;
    }
    & > li:nth-child(8) {
      background-position: -672px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 40px;
      right: 0px;
    }
  }
  & > .indexBottomNav2 {
    width: 1166px;
    height: 280px;
    margin: 0 auto;
    position: relative;
    & > li {
      width: 78px;
      height: 100px;
      position: absolute;
      z-index: 10000;
    }
    & > li:nth-child(1) {
      background-position: 0px 0px;
      background: url(../assets/image/indexNav.png) no-repeat;
      top: 33px;
      left: 160px;
    }
    & > li:nth-child(2) {
      background-position: -90px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 25px;
      left: 320px;
    }

    & > li:nth-child(3) {
      background-position: -280px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 20px;
      left: 480px;
    }
    & > li:nth-child(4) {
      background-position: -480px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 22px;
      left: 640px;
    }
    & > li:nth-child(5) {
      background-position: -180px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 25px;
      left: 800px;
    }
    & > li:nth-child(6) {
      background-position: -572px 0px;
      background-image: url(../assets/image/indexNav.png);
      top: 30px;
      left: 960px;
    }
  }
}
/deep/ .el-pagination button:disabled {
  background-color: transparent !important;
}
/deep/ .el-pager li {
  background: transparent !important;
}

::v-deep .BMap_bubble_pop {
  padding: 0 !important;
  background-color: transparent !important;
  background-image: url(../assets/image/mapTips.png), url(../assets/image/mapBg.png);
  background-position: 0 0, center 140px;
  background-repeat: no-repeat;
  border: none !important;
  margin-top: 45px;
  margin-left: 15px;
  width: 170px !important;
  height: 155px !important;
}
::v-deep .BMap_bubble_center {
  width: 170px !important;
  height: 155px !important;
}
::v-deep .BMap_bubble_title {
  color: white !important;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgba(23, 70, 104, 0.6) !important;
  opacity: 0.8;
}
::v-deep .BMap_bubble_content {
  width: 165px !important;
  height: 140px !important;
  top: 0px;
}

::v-deep .BMap_bubble_top {
  display: none;
}

::v-deep .BMap_bubble_bottom ~ img {
  display: none;
}
@font-face {
  font-family: "fangzheng";
  src: url("../assets/font/fangzheng.ttf") format("opentype");
}
</style>
