import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import layout from "../layout/index.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/home",
    component: layout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/timedTask",
        name: "timedTask",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/timedTask.vue"),
      },
      {
        path: "/temporaryDuty",
        name: "temporaryDuty",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/temporaryDuty.vue"),
      },
      {
        path: "/eventLinkage",
        name: "eventLinkage",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/eventLinkage.vue"),
      },
      {
        path: "/broadcastLive",
        name: "broadcastLive",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/broadcastLive.vue"),
      },
      {
        path: "/libraryManagement",
        name: "libraryManagement",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/libraryManagement.vue"
          ),
      },
      {
        path: "/userManagement",
        name: "userManagement",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/userManagement.vue"),
      },
      {
        path: "/mapManagement",
        name: "mapManagement",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/mapManagement.vue"),
      },
      {
        path: "/baseManagement",
        name: "baseManagement",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/baseManagement.vue"),
      },
      {
        path: "/logQuery",
        name: "logQuery",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/logQuery.vue"),
      }
    ],
  },

  {
    path: "/equipmentList",
    name: "equipmentList",
    component: () => import("../layout/index2.vue"),
    children: [
      {
        path: "/equipmentList",
        name: "equipmentList",
        component: () => import("../views/equipmentList.vue"),
      },
      {
        path: "/userManagementAdmin",
        name: "userManagementAdmin",
        component: () => import("../views/userManagementAdmin.vue"),
      },
      {
        path: "/basicSetup",
        name: "basicSetup",
        component: () => import("../views/basicSetup.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory("/"),
  routes,
});

export default router;
