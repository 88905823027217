import request from "./request";

// 登录接口
export function login(data) {
  return request({
    url: "/chat/login",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

// 修改密码
export function changePassword(data) {
  return request({
    url: "/chat/change_password",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

// 用户权限
export function getLoginRole(data) {
  return request({
    url: "/chat/get_login_role",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//退出登录
export function logout(data) {
  return request({
    url: "/chat/logout",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//获取首页标题
export function getPlatformTitle(data) {
  return request({
    url: "/chat/get_platform_title",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//设置首页标题
export function setPlatformTitle(data) {
  return request({
    url: "/chat/set_platform_title",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//首页噪音 
export function visualNoise(data) {
  return request({
    url: "/chat/visual_noise",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//首页-设备数据
export function getDeviceTotal(data) {
  return request({
    url: "/chat/get_device_total",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//首页-同意音量
export function alldeviceVolume(data) {
  return request({
    url: "/chat/alldevice_volume",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//获取设备列表
export function deviceList(data) {
  return request({
    url: "/chat/get_device_list",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//任务列表
export function getTasksSummary(data) {
  return request({
    url: "/chat/get_tasks_summary",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//曲目列表
export function getSongTable(data) {
  return request({
    url: "/chat/get_song_table",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//实时广播-开始广播
export function broadcastCall(data) {
  return request({
    url: "/chat/broadcast_call",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//地图管理-设置模式
export function setMapfileMode(data) {
  return request({
    url: "/chat/set_mapfile_mode",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}


//创建账户
export function loginuserAdd(data) {
  return request({
    url: "/chat/loginuser_add",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//账户列表
export function loginuserList(data) {
  return request({
    url: "/chat/loginuser_gets",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//新增任务
export function tasksAdd(data) {
  return request({
    url: "/chat/tasks_add",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//上传音乐
export function uploadFile(data) {
  return request({
    url: "/chat/upload_file",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//曲库列表
export function getUpdateStatus(data) {
  return request({
    url: "/chat/get_update_status",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//上传文字
export function uploadText(data) {
  return request({
    url: "/chat/upload_text",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    // responseType: 'blob',
    data,
  });
}

//上传文字
export function uploadTextCheck(data) {
  return request({
    url: "/chat/upload_text_check",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//上传文字-预览
export function downloadMediaFile(params) {
  return request({
    url: "chat/download_media_file",
    method: "get",
    header: {
      "Content-Type": "application/json",
    },
    params,
  });
}
//任务详情
export function getTasksDetails(data) {
  return request({
    url: "/chat/get_tasks_details",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//实时广播-设置音量
export function setDeviceVolume(data) {
  return request({
    url: "/chat/set_device_volume",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//地图页面-获取模式 
export function getMapfileMode(data) {
  return request({
    url: "/chat/get_mapfile_mode",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//编辑任务
export function tasksMod(data) {
  return request({
    url: "/chat/tasks_mod",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//删除任务
export function tasksDel(data) {
  return request({
    url: "/chat/tasks_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//播放音乐
export function tasksPlay(data) {
  return request({
    url: "/chat/tasks_play",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//暂停播放
export function tasksPuse(data) {
  return request({
    url: "/chat/tasks_pause",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//停止播放
export function tasksStop(data) {
  return request({
    url: "/chat/tasks_stop",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//获取临时任务列表
export function tempTasksGets(data) {
  return request({
    url: "/chat/temp_tasks_gets",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//临时任务-暂停
export function tempTasksPause(data) {
  return request({
    url: "/chat/temp_tasks_pause",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//停止播放
export function tempTasksStop(data) {
  return request({
    url: "/chat/temp_tasks_stop",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//临时任务-上一曲，下一曲
export function tempTasksSong(data) {
  return request({
    url: "/chat/temp_tasks_song",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//音量+ -操作
export function setTasksVolume(data) {
  return request({
    url: "/chat/set_tasks_volume",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//临时任务--音量进步值
export function tempTasksVolume(data) {
  return request({
    url: "/chat/temp_tasks_volume",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}


// 曲目+ -操作
export function tasksSongSelect(data) {
  return request({
    url: "/chat/tasks_song_select",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//获取分区
export function getAreaDegree(data) {
  return request({
    url: "/chat/get_area_degree",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//获取分区
export function getAreaDegreeB2(data) {
  return request({
    url: "/chat/get_area_degree2",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//基本设置--设置设备名称
export function setDeviceName(data) {
  return request({
    url: "/chat/set_device_name",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//获取指定分区设备列表
export function getDeviceByArea(data) {
  return request({
    url: "/chat/get_device_by_area",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//临时任务-任务栏
export function tempTasksPlay(data) {
  return request({
    url: "/chat/temp_tasks_play",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//临时任务-创建任务栏
export function tempTasksCreate(data) {
  return request({
    url: "/chat/temp_tasks_create",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//新增文件夹
export function folderAdd(data) {
  return request({
    url: "/chat/folder_add",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//上传文件提交审核
export function uploadFileCheck(data) {
  return request({
    url: "/chat/upload_file_check",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//审核当前文件
export function uploadFilePass(data) {
  return request({
    url: "/chat/upload_file_pass",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//删除文件
export function uploadFileDel(data) {
  return request({
    url: "/chat/upload_file_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//同步设备
export function devicesSync(data) {
  return request({
    url: "/chat/devices_sync",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//同步设备-状态
export function devicesSyncStatus(data) {
  return request({
    url: "/chat/devices_sync_status",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function syncProgress(data) {
  return request({
    url: "/chat/sync_progress",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//基本设置--自动增益，默认声音  
export function setGainVol(data) {
  return request({
    url: "/chat/set_gain_vol",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//地图设置
export function setMapCentral(data) {
  return request({
    url: "/chat/set_map_central",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

// 地图管理-设备经纬度修改   setDevicePosition params:[设备ID,经度，纬度]
export function setDevicePosition(data) {
  return request({
    url: "/chat/set_device_position",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//上传图纸
export function uploadMapfile(data) {
  return request({
    url: "/chat/upload_mapfile",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//获取图纸
export function reloadMapfile(data) {
  return request({
    url: "chat/reload_mapfile",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//新建分区
export function areaCreate(data) {
  return request({
    url: "/chat/area_create",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//设备状态更新
//devices_status_update  params:['设备id1',...]
export function deviceStatusUpdate(data) {
  return request({
    url: "/chat/device_status_update",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//编辑分区
export function areaMod(data) {
  return request({
    url: "/chat/area_mod",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//曲库管理-删除歌曲文件
export function libraySongDel(data) {
  return request({
    url: "/chat/libray_song_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//用户管理-删除账号
export function loginuserDel(data) {
  return request({
    url: "/chat/loginuser_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//用户管理-删除文件
export function loginuserMod(data) {
  return request({
    url: "/chat/loginuser_mod",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//基本设置-删除文件
export function areaDel(data) {
  return request({
    url: "/chat/area_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//基本设置-新增下级
export function subAreaAdd(data) {
  return request({
    url: "/chat/sub_area_add",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//日志
export function getLogs(data) {
  return request({
    url: "/chat/get_logs",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//后台页面的接口--------------------------------------------------------------------------------
// 修改密码-admin
export function changePasswordAdmin(data) {
  return request({
    url: "/system/change_password",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

// 登录接口
export function systemlogin(data) {
  return request({
    url: "/system/login",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//退出登录
export function systemlogout(data) {
  return request({
    url: "/system/logout",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//设备列表
export function getDeviceListAdmin(data) {
  return request({
    url: "/system/get_device_all",
    method: "post",
    data,
  });
}

export function uploadFileLogo(data) {
  return request({
    url: "/system/upload_logo",
    method: "post",
    data,
  });
}

export function uploadFileGu(data) {
  return request({
    url: "/system/upgrade_file",
    method: "post",
    data,
  });
}

//固件升级
export function upgradeFirmware(data) {
  return request({
    url: "/system/upgrade_firmware",
    method: "post",
    data,
  });
}
//用户管理
export function systemloginuserList(data) {
  return request({
    url: "/system/loginsys_gets",
    method: "post",
    data,
  });
}

//新增用户
export function loginsysAdd(data) {
  return request({
    url: "/system/loginsys_add",
    method: "post",
    data,
  });
}

//设备列表-查询按钮
export function getAreaDegree2(data) {
  return request({
    url: "/system/get_area_degree",
    method: "post",
    data,
  });
}

//设备列表-分区查询
export function getDeviceByArea2(data) {
  return request({
    url: "/system/get_device_by_area",
    method: "post",
    data,
  });
}

//流量查询
export function getDevicesTraffic(data) {
  return request({
    url: "/system/get_devices_traffic",
    method: "post",
    data,
  });
}

export function deviceAdd(data) {
  return request({
    url: "/system/device_add",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//设备列表-编辑设备
export function devicesModify(data) {
  return request({
    url: "/system/devices_modify",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//删除用户
export function loginsysDel(data) {
  return request({
    url: "/system/loginsys_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//编辑用户
export function loginsysMod(data) {
  return request({
    url: "/system/loginsys_mod",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//基本设置
export function networkSet(data) {
  return request({
    url: "/system/network_set",
    method: "post",
    data,
  });
}

//基本设置
export function networkGets(data) {
  return request({
    url: "/system/network_gets",
    method: "post",
    data,
  });
}
export function upgradeProgress(data) {
  return request({
    url: "/system/upgrade_progress",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//删除设备 
export function deviceDel(data) {
  return request({
    url: "/system/device_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function loginsysImport(data) {
  return request({
    url: "/system/loginsys_import",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function unsetDevicesIccid(data) {
  return request({
    url: "/system/unset_devices_iccid",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function setDevicesIccid(data) {
  return request({
    url: "/system/set_devices_iccid",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function wxpayGet(data) {
  return request({
    url: "/system/wxpay_get",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function wxpaySet(data) {
  return request({
    url: "/system/wxpay_set",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getLoginType(data) {
  return request({
    url: "/system/get_login_type",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function folderMod(data) {
  return request({
    url: "/chat/folder_mod",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function folderDel(data) {
  return request({
    url: "/chat/folder_del",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function loginsysExport(data) {
  return request({
    url: "/system/loginsys_export",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}


export function getDeviceInfo(data) {
  return request({
    url: "/chat/get_device_info",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function getTaskSong(data) {
  return request({
    url: "/chat/get_task_song",
    method: "post",
    header: {
      "Content-Type": "application/json",
    },
    data,
  });
}
