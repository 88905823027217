import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

import "./styles/reset.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// import zhCn from "element-plus/es/locale/lang/zh-cn";

import "@/styles/common.scss";

import "@/assets/icons"; // 导入图标资源
import SvgIcon from "@/components/SvgIcon.vue"; // svg component
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App);
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(ElementPlus, { size: "default", zIndex: 30000 });
// app.use(ElementPlus, { locale: zhCn });
app.use(createPinia());
app.use(router);
app.component("svg-icon", SvgIcon);
app.mount("#app");
