import axios from "axios";
import { ElMessage } from "element-plus";
import router from "../router/index";
// create an axios instance
let apiUrl = "";
if (process.env.NODE_ENV == "development") {
  apiUrl = "api"
} else {
  apiUrl = "";
}
const service = axios.create({
  baseURL: apiUrl, // url = base url + request url
  // baseURL: 'api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (window.localStorage.getItem("systemToken")) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] =
        window.localStorage.getItem("systemToken");
    }
    return config;
  },
  (error) => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // console.log(response);
    let errArr = [
      "e1000",
      "e1001",
      "e1002",
      "e1003",
      "e1004",
      "e1005",
      "e1006",
      "e1007",
      "e1008",
      "e1009",
      "e1010",
      "e1011",
      "e1012",
    ];
    let errObj = {
      e1000: "类型错误",
      e1001: "分区不存在",
      e1002: "设备不存在",
      e1003: "输入参数错误",
      e1004: "网络连接失败",
      e1005: "上级分区不存在",
      e1006: "禁止操作",
      e1007: "名字不存在",
      e1008: "参数被占用",
      e1009: "时间冲突",
      e1010: "没有权限",
      e1011: "任务忙",
      e1012: "操作失败",
    };
    if (typeof response.data == "string") {
      let strIndex = -1;
      errArr.map((m) => {
        if (response.data.indexOf(m) > -1) {
          strIndex = response.data.indexOf(m);
        }
      });
      if (strIndex > -1) {
        let statusStr = response.data.substring(strIndex, strIndex + 5);
        ElMessage({
          message: errObj[statusStr] || "Error",
          type: "error",
          duration: 5 * 1000,
          customClass: "mzindex",
        });
        return false;
      }
    }
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.error != null) {
      ElMessage({
        message: errObj[res.error] || "Error",
        type: "error",
        duration: 5 * 1000,
        customClass: "mzindex",
      });

      return Promise.reject(new Error("Error"));
    } else {
      return res;
    }
  },
  (error) => {
    if (error.request.status == 401) {
      router.push("/login");
      ElMessage({
        message: "登录已过期！",
        type: "error",
        duration: 5 * 1000,
        customClass: "mzindex",
      });
      return false;
    }
    ElMessage({
      message: error.message || "系统错误",
      type: "error",
      duration: 5 * 1000,
      customClass: "mzindex",
    });
    return Promise.reject(error);
  }
);

export default service;